import React, { useState } from "react";
import "./UnderConstruction.css"; // Make sure to create this CSS file

function UnderConstruction() {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    // Here you could add logic to handle the email submission, like sending it to an API

    // Clear the input field by setting the email state back to an empty string
    setEmail("");

    // Optionally, you could also provide feedback to the user or perform other actions here
  };

  return (
    <div className="under-construction">
      <h1>Site Under Construction</h1>
      <p>
        We're working hard to finish the development of this site. Sign up below
        to receive updates and to be notified when we launch!
      </p>
      <input
        type="email"
        placeholder="Your email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleSubmit}>Subscribe</button>
    </div>
  );
}

export default UnderConstruction;
